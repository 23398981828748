import React from 'react';

import { gql, useMutation } from '@apollo/client';

import { Box } from '@mui/material';

const M_LOGIN=gql`
mutation {
  authUrl
}
`;

function Auth() {
  const [ login ] = useMutation( M_LOGIN, {
    onCompleted: ( d => applyLogin( d ) ),
    onError: ( d => console.log( d ) )
  });

  if ( window.location.search === "?logout=true" ) {
    return <div>Logged out</div>;
  } else {
    setTimeout(login, 3000);
    return <div>
             <Box sx={{ p: 4 }}>
               Please login to activate.
             </Box>
             <Box sx={{ p: 4 }}>
               Redirecting to login in 3 seconds...
             </Box>
           </div>;
  }
}

function applyLogin( data ) {
  window.location=(data.authUrl);
}

export default Auth;
