import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import { Box, TextField, Button } from '@mui/material';

const PING_QUERY=gql`
{
ping( any: "check!" )
}
`;

const ACTIVATE=gql`
mutation activate($code:String!) {
  activateCode(code:$code)
}
`;

function Main() {
  var resp="starting";
  const [ lpcode, setLpcode ] = useState("");
  const [ notice, setNotice ] = useState(false);
  const [ errorMsg, setError ] = useState(false);

  const [ callActivate, activateStatus ] =
        useMutation( ACTIVATE, {
          onCompleted: data => {
            setNotice( data.activateCode );
          },
          onError: error => {
            if ( error.networkError ) {
              setError("Has some network problem, how's your connection?");
            } else if ( error.graphQLErrors && error.graphQLErrors.length > 0 ) {
              setError( error.graphQLErrors[0].message );
            }
          }
        }
                   );

  const { loading, error, data } = useQuery(PING_QUERY);
  if ( loading )  { resp="loading..."; }
  else if ( error ) { resp=`An error: ${error.message}`; }
  else { resp=data.ping; }

  const submitCode = () => {
    callActivate( { variables: { code: lpcode } } );
  };

  const inputRe = new RegExp('^[a-zA-Z0-9]*$');

  const handleChange = ( event ) => {
    event.preventDefault();

    const new_val = event.target.value;
    if (inputRe.test(new_val)) {
      setLpcode(new_val);
    }
  };

  if ( resp !== "pong" ) {
    return(
      <div>The server is not responding as expected</div>
    );
  }

  if ( activateStatus.loading ) {
    return(
      <Box sx={{m: 3, p: 2 }}>
        Sending your code
      </Box>
    );
  }

  if ( notice ) {
    return(
      <Box sx={{m: 3, p: 2 }}>
        { notice }
      </Box>
    );
  }

  return(
    <Box
      component="form"
      sx={{
        m: 3,
        color: 'primary.contrastText',
        p: 2
      }}
      noValidate
      autoComplete="off"
      onSubmit={e => e.preventDefault() }
    >
      <Box sx={{m: 1, p: 1, color: 'warning.main' }}>
        { errorMsg }
      </Box>
      <div>
      <TextField
        id="outlined-basic"
        label="Activation Code"
        variant="outlined"
        value={lpcode}
        onChange={handleChange}
      />
      </div>
      <div>
        <Button
          sx={{m: 2}}
          disabled={lpcode.length < 4}
          onClick={submitCode}
        >Activate</Button>
    </div>
    </Box>
  );
}

export default Main;
