import React from 'react';
import './App.css';

import { ApolloClient, HttpLink, InMemoryCache, ApolloLink,
         concat, ApolloProvider } from '@apollo/client';

import { onError } from '@apollo/client/link/error';

import { gql, useQuery } from '@apollo/client';

import CssBaseline from '@mui/material/CssBaseline';

import LoginStatus from './LoginStatus';
import Main from './Main';

const httpLink = new HttpLink({
  uri: document.apiUrl || process.env.REACT_APP_API_URL,
  credentials: 'include'
 });

const errhandler = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      {
        return console.error(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`);
      });
  }
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

let csrf_token;

const authLink = new ApolloLink( (operation, forward) => {
  operation.setContext({
    headers: {
      'x-csrf-token': csrf_token
    }
  });
  return forward( operation ).map( response => {
    const possible_csrf_token = operation.getContext().response.headers.get("x-csrf-token");
    if ( possible_csrf_token ) {
      csrf_token = possible_csrf_token;
    }
    return response;
  });
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authLink, concat(errhandler, httpLink)),
});

// function getCache() { return cache; }

const Q_SYNC=gql`
query { ping_sync }
`;

function App() {
  const { loading, error } = useQuery( Q_SYNC, { client: client } );

  if ( loading ) {
    return( <div>Loading</div> );
  }

  if ( error ) {
    return( <div>`We have an error: ${error.message}`</div> );
  }

  return(
    <div className="App">
      <React.Fragment>
        <CssBaseline />
        <ApolloProvider client={client}>
          <LoginStatus>
            <Main/>
          </LoginStatus>
        </ApolloProvider>
      </React.Fragment>
    </div>
  );
}

export default App;
